<template>
  <fragment>
    <app-header title="Organisation Details" v-if="$isA('provider')"/>
    <slot name="tabBar" />
    <div class="container mt-4">
      <average-score-card :provider="this.provider"></average-score-card>
      <average-course-score :provider="this.provider"></average-course-score>
    </div>

    <slot name="footer" />
  </fragment>
</template>

<script>
import { get } from 'vuex-pathify'
import AverageScoreCard from '@/views/AverageScoreCard.vue'
import AverageCourseScore from '@/views/AverageCourseScore.vue'

export default {
  components: {
    AverageScoreCard,
    AverageCourseScore
  },

  computed: {
    provider: get('providers/provider'),
  },

  methods: {
  }
}
</script>
