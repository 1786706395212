<template>
  <div :class="[invalidClass]">
    <input
      type="file"
      ref="file"
      class="form-control"
      :class="[invalidClass]"
      @change="fileSelectionChanged"
    />
    <small class="form-text">
      Maximum 5MB. File types: jpg, gif, png.
      <slot name="imageDimensionMessage"/>
    </small>
    <div class="file-details" v-if="exists">
      <span class="extension">{{ extension }}</span>
      <span class="filename">{{ file.name }}</span>
      <span class="file-size">{{ fileSize }}</span>
      <a href="" class="remove-file" @click.prevent="confirmBeforeDelete"><i class="fa fa-trash-o"></i> Delete File</a>
      <c-button
        class="btn-sm btn-download"
        variant="outline-primary"
        @click="download"
      >
        <i class="far fa-cloud-download me-1"></i>
        <span class="d-none d-lg-inline">Download</span>
      </c-button>
    </div>
  </div>
</template>

<script>
import { http } from '@/utils'
import { useIsInvalid } from '@chameleon/chameleon-vue'
import { downloadBlob } from '@/utils/functions/download-blob'
import swal from 'sweetalert'

export default {
  mixins: [useIsInvalid],

  name: 'FileUpload',

  props: {
    value: {
      type: File,
      default: undefined,
    },
    file: {
      type: Object,
      required: false,
      default: () => ({}),
    }
  },

  computed: {
    exists () {
      return this.file && this.file.hash !== undefined && this.file.hash !== null
    },

    extension () {
      return this.exists ? this.file.extension.toUpperCase() : null
    },

    fileSize () {
      return this.exists ? `${this.file.size}MB` : null
    }
  },

  watch: {
    value () {
      if (this.value === null && this.$refs.file) {
        this.$refs.file.value = ''
      }
    }
  },

  methods: {
    fileSelectionChanged (event) {
      if (event.target.files.length === 0) {
        this.$emit('input', null)

        return
      }

      // if (this.parentProvider) {
      //   this.parentProvider.reset()

      //   await this.parentProvider.validate(event)
      // }

      const file = event.target.files.item(0)

      this.$emit('input', file)
    },

    async confirmBeforeDelete () {
      if (
        await swal({
          text: 'Are you sure you want to delete this file?',
          buttons: {
            cancel: { visible: true },
            confirm: { text: 'Delete', visible: true },
          },
          dangerMode: true
        })
      ) {
        this.$emit('delete')
      }
    },

    async download () {
      const response = await http.get(this.file.url, { responseType: 'blob' })

      downloadBlob(response.data, this.file.name)
    },
  }
}
</script>
