<template>
  <fragment>
    <div class="col main-content">
      <slot name="tabBar" />

      <dashboard-provider :providerId="this.provider.id"></dashboard-provider>

      <vue-scroll>
        <div class="container mt-4">
          <div class="row">
            <div class="col d-flex align-items-center">
              <strong>Search Results</strong>
            </div>
            <div class="col-auto">
              <div class="row">
                <label class="col-auto col-form-label">Sort By</label>
                <div class="col">
                  <sort-select v-model="sortSelected" />
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="row">
                <div class="form-check form-switch">
                  <input type="checkbox" v-model="filters.past" id="showPastEvents" class="form-check-input" />
                  <label for="showPastEvents" class="form-check-label">Show Past Events</label>
                </div>
              </div>
            </div>
          </div>

          <c-data-list
            :items="courses"
            @load="load"
            class="results-list"
            target-vue-scroll
            remove-default-class
          >
            <template #default="{ item: course }">
              <course-card v-bind="{ course }">
                <template #like-btn>
                  <span class="like align-right-1">
                    <i class="fad fa-thumbs-up"></i>
                    {{ course.likes_count }}
                  </span>
                </template>
              </course-card>
            </template>
          </c-data-list>
        </div>

        <slot name="footer" />
      </vue-scroll>
    </div>

    <filters-sidesheet
      cols="col-xl-4 col-xxl-3"
      v-model="filters"
      v-bind="{ sidesheetOpen }"
      @close="sidesheetOpen = false"
      on-status
      on-keywords
      on-start-date-range
      on-type
      on-locations
      on-professions
      on-topics
    />

    <portal to="header">
      <add-course-button/>
    </portal>

    <portal to="headerRight">
      <ul class="header-nav justify-content-end">
        <li>
          <button
            type="button"
            class="btn btn-sidesheet-show search navbar-user-link d-xl-none"
            @click="sidesheetOpen = true"
          >
            <i class="fal fa-search"></i> Search
          </button>
        </li>
      </ul>
    </portal>
  </fragment>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { useQueryParamFilters, compareQueryParams } from '@codium/codium-vue'
import FiltersSidesheet from '@/components/courses/FiltersSidesheet.vue'
import CourseCard from '@/components/courses/admin/CourseCard.vue'
import SortSelect from '@/components/courses/SortSelect.vue'
import AddCourseButton from '@/components/courses/AddCourseButton.vue'
import DashboardProvider from '@/views/DashboardProvider.vue'

export default {
  mixins: [useQueryParamFilters()],

  components: {
    FiltersSidesheet,
    CourseCard,
    SortSelect,
    AddCourseButton,
    DashboardProvider
  },

  data () {
    return {
      sidesheetOpen: false,
      filters: {
        status: null,
        keywords: null,
        start_date_from: null,
        start_date_to: null,
        locations: [],
        professions: [],
        topics: [],
        primaryFields: [],
        type: null,
        sort: 'date',
        order: 'desc',
        past: false
      }
    }
  },

  computed: {
    provider: get('providers/provider'),
    courses: get('courses/courses'),

    sortSelected: {
      get () {
        return { sort: this.filters.sort, order: this.filters.order }
      },

      set ({ sort, order }) {
        this.filters.sort = sort
        this.filters.order = order
      }
    }
  },

  watch: {
    '$route.query' (query) {
      if (Object.keys(query).length === 0) {
        this.filters = this.$options.data().filters
      }
    },

    queryParams: compareQueryParams(async function (params) {
      await this.getCourses({ params: { ...params, providers: [this.provider.id] }, fresh: true })
    })
  },

  beforeDestroy () {
    this.resetCourses()
  },

  methods: {
    getCourses: call('courses/getCourses'),
    resetCourses: call('courses/resetCourses'),

    async load (done) {
      try {
        await this.getCourses({ params: { ...this.queryParams, providers: [this.provider.id] } })

        done()
      } catch {
        done(true)
      }
    }
  }
}
</script>
