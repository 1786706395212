<template>
  <fragment>
    <app-header title="Organisation Subscription" v-if="$isA('provider')"/>
    <slot name="tabBar" />
    <div class="container container-max-md mt-4">
      <div class="card">
        <validation-observer
          tag="div"
          class="card-body"
          ref="validation"
          @submit.native.prevent
        >

          <h2>Update Billing Email</h2>

          <p>
            Subscription and billing related communications will be sent to this
            email address. Separate multiple email addresses via comma.
          </p>

          <validation-provider class="mb-3" name="billing_emails" rules="required">
            <label class="form-label">Billing Email Address</label>
            <c-input type="text" class="form-control" v-model="billingEmails" />
            <c-help-block />
          </validation-provider>

          <div class="text-end mt-4">
            <c-button type="button" class="btn btn-primary" @click="save">
              Save
            </c-button>
          </div>
        </validation-observer>
      </div>
    </div>
  </fragment>
</template>

<script>
import { call, get } from 'vuex-pathify'
import { stateMapper } from '@/vuex/modules/active-subscriptions'
import { toast } from '@chameleon/chameleon-vue'

export default {
  mixins: [stateMapper],

  async created () {
    await this.getActiveSubscription({
      type: 'providers',
      id: this.provider.id,
    })
  },

  computed: {
    activeSubscription: get('activeSubscriptions/activeSubscription'),
    provider: get('providers/provider'),

    billingEmails: {
      get () {
        return this.activeSubscription.billing_emails.join(', ')
      },

      set (value) {
        this.stateBillingEmails = value
          .replace(/\s/g, '')
          .split(',')
      },
    },
  },

  methods: {
    getActiveSubscription: call('activeSubscriptions/getActiveSubscription'),
    updateBillingEmail: call('activeSubscriptions/updateBillingEmail'),

    async save () {
      try {
        await this.updateBillingEmail({
          payload: { billing_emails: this.stateBillingEmails },
          providerId: this.provider.id,
        })
        toast('Successfully updated billing email.', 'success')
      } catch (err) {
        if (err.response.status === 422) {
          this.$refs.validation.setErrors({
            billing_emails: 'Please enter valid email addresses seperated by a comma.',
          })
        }
      }
    },
  },
}
</script>
