<template>
  <fragment>
    <app-header :title="pageTitle" />

    <div :class="wrappingClass">
      <router-view v-if="provider.id">
        <template #tabBar>
          <ul id="horizontal-nav" class="nav nav-horizontal">
            <router-link tag="li" :to="{ name: 'providers.single.courses', params: { id: provider.id } }" class="nav-item">
              <a class="nav-link">Courses</a>
            </router-link>
            <router-link tag="li" :to="{ name: 'providers.single.details', params: { id: provider.id } }" class="nav-item" exact>
              <a class="nav-link">Organisation Details</a>
            </router-link>
            <router-link tag="li" :to="{ name: 'admin.providers.single.learner-quality-score', params: { id: provider.id } }" class="nav-item" exact>
              <a class="nav-link">Learner Quality Score</a>
            </router-link>
            <router-link tag="li" :to="{ name: 'admin.providers.single.subscription', params: { id: provider.id } }" class="nav-item">
              <a class="nav-link">Subscription</a>
            </router-link>
            <router-link tag="li" :to="{ name: 'providers.single.users', params: { id: provider.id } }" class="nav-item">
              <a class="nav-link">Users</a>
            </router-link>
          </ul>
        </template>
      </router-view>

      <slot name="footer"/>
    </div>
  </fragment>
</template>

<script>
import { helperMethods, helperComputed } from '@/vuex/modules/providers'
import { can } from '@codium/codium-vue'
import { toast } from '@chameleon/chameleon-vue'

export default {
  data () {
    return {
      pageTitle: 'Training Provider'
    }
  },

  computed: {
    ...helperComputed,

    wrappingClass () {
      if (this.$route.name === 'providers.single.courses') {
        return 'layout-sidesheet row'
      }
      return null
    }
  },

  async created () {
    try {
      await this.getProvider(this.$route.params.providerId)
      if (can('read users')) {
        return null
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast(error.response.data.message, 'danger')
      } else {
        throw error
      }
    }
  },

  methods: {
    ...helperMethods
  }
}
</script>
