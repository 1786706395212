<template>
  <fragment>
    <app-header :title="provider.name" />

    <div class="container" v-if="provider.id">
      <!-- back button -->
      <div class="mb-4">
        <a href="" @click.prevent="$router.go(-1)" class="text-primary">
          <i class="far fa-chevron-left me-2"></i> Back
        </a>
      </div>

      <article class="card card-training-provider-details">
        <!-- featured image -->
        <img :src="featuredImageUrl" width="1280" height="720" class="featured">
        <!-- logo -->
        <img :src="logoUrl" width="256" height="256" alt="" class="logo">
        <div class="row">
          <!-- description -->
          <section class="col-xl-8">
            <div v-html="provider.long_description" class="card-body card-body-lg pe-xl-0">
            </div>
          </section>
          <section class="col-xl-4">
            <div class="card-body card-body-lg">
              <h1 class="h6">{{ provider.name }}</h1>
              <p class="text-muted">{{ provider.address.country }} / {{ provider.address.state }}</p>
              <ul class="list-provider-details mt-4">
                <li>
                  <i class="far fa-fw fa-browser me-2"></i>
                  <a :href="provider.website" target="_blank" @click="logClickActivity('website')">{{ provider.website }}</a>
                </li>
                <li>
                  <i class="far fa-fw fa-phone-alt me-2"></i>
                  <a :href="`tel:${provider.phone_number}`" target="_blank" @click="logClickActivity('contact_phone')">{{ provider.phone_number }}</a>
                </li>
                <li v-if="provider.email">
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-small"
                    v-c-modal:providerContactModal
                  >
                    <i class="fas fa-envelope" />
                     Contact Provider
                  </button>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </article>

      <div class="row mt-5 mb-3">
        <div class="col d-flex align-items-center">
          <h2 class="mb-0">Courses</h2>
        </div>
        <div class="col-auto">
          <router-link :to="{ name: 'courses', query: { providers: provider.id } }" class="btn btn-md btn-primary">
            View All
          </router-link>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-md-6 col-xl-4 d-flex" v-for="course in courses" :key="course.id">
          <course-card :course="course" stacked>
            <template #bookmark>
              <bookmark-button v-bind="{ course }" v-c-modal:addLearningPlan="{ course }" />
            </template>
          </course-card>
        </div>
      </div>

      <div class="row mb-4">
        <average-score-card :provider="provider"></average-score-card>
      </div>

    </div>

    <add-learning-plan-modal
      ref="addLearningPlan"
      v-bind="{ courses }"
      @updated="setCourses"
    />
    <provider-contact-modal
      ref="providerContactModal"
      v-bind="{ provider }">
    </provider-contact-modal>
  </fragment>
</template>

<script>
import { call, get, sync } from 'vuex-pathify'
import { placeholders } from '@/utils/placeholders'
import AddLearningPlanModal from '@/components/courses/AddLearningPlanModal.vue'
import CourseCard from '@/components/courses/user/CourseCard.vue'
import BookmarkButton from '@/components/learning-plans/BookmarkButton.vue'
import ProviderContactModal from '@/components/providers/ProviderContactModal.vue'
import AverageScoreCard from '@/components/AverageScoreCard.vue'

export default {
  components: {
    CourseCard,
    AddLearningPlanModal,
    BookmarkButton,
    ProviderContactModal,
    AverageScoreCard
  },

  props: {
    providerId: Number
  },

  computed: {
    provider: get('providers/provider'),
    courses: sync('providers/courses/courses'),

    logoUrl () {
      return this.provider.square_logo_url ?? placeholders.square
    },

    featuredImageUrl () {
      return this.provider.featured_image_url ?? placeholders.large
    }
  },

  async created () {
    await this.getProvider(this.providerId)
    await this.getRecentlyPublishedCourses()
  },

  beforeDestroy () {
    this.resetProvider()
    this.resetCourses()
  },

  methods: {
    getProvider: call('providers/getProvider'),
    getRecentlyPublishedCourses: call('providers/courses/getRecentlyPublishedCourses'),
    resetCourses: call('providers/courses/resetCourses'),
    resetProvider: call('providers/resetProvider'),
    storeUserActivity: call('users/storeUserActivity'),

    setCourses (courses) {
      this.courses = courses
    },

    async logClickActivity (link) {
      await this.storeUserActivity({ activity: 'click', subjectType: 'provider', subjectId: this.provider.id, properties: { link } })
    },
  }
}
</script>
