<template>
  <fragment>
    <app-header title="Training Providers">
      <template #actions>
        <router-link
          class="btn btn-primary btn-md"
          :to="{ name: 'providers.add' }"
        >
          <i class="far fa-plus"></i> Add Provider
        </router-link>
      </template>
    </app-header>

    <div class="container">
      <list-filters v-model="params" />

      <c-data-list :items="providers" @load="load" class="list-data no-hover">
        <template #columns>
          <div class="col-7">Name</div>
          <div class="col-2">Users</div>
          <div class="col-3">Status</div>
        </template>
        <template #default="{ item }">
          <!-- list item -->
          <router-link :to="{ name: 'providers.single.courses', params: { providerId: item.id} }">
            <div class="row">
              <div class="col-12 col-lg-7  strong">
                <img v-if="item.square_logo_url" :src="item.square_logo_url" width="150" height="150" alt="" class="thumbnail">
                <img v-else :src="placeholders.square" width="150" height="150" alt="" class="thumbnail">
                {{ item.name }}
              </div>
              <div class="col-6 col-lg-2">
                <p><span class="data-label d-lg-none">Users</span>{{ item.users_count }}</p>
              </div>
              <div class="col-6 col-lg-3">
                <span class="status" :class="statusClass(item.status)">{{ item.status }}</span>
              </div>
            </div>
          </router-link>
        </template>
      </c-data-list>
    </div>

    <slot name="footer" />
  </fragment>
</template>

<script>
import { compareQueryParams } from '@codium/codium-vue'
import { helperComputed, helperMethods } from '@/vuex/modules/providers'
import ListFilters from './ProvidersListFilters'
import { toast } from '@chameleon/chameleon-vue'
import { placeholders } from '@/utils/placeholders'

export default {
  components: {
    ListFilters
  },

  data () {
    return {
      params: null,
      placeholders
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getList({ params, fresh: true })
    })
  },

  computed: {
    ...helperComputed,
  },

  beforeDestroy () {
    this.resetProviders()
  },

  methods: {
    ...helperMethods,

    async load (done) {
      const { params } = this
      try {
        await this.getList({ params })
        done()
      } catch {
        done(true)
      }
    },

    async getList ({ params = {}, fresh = false }) {
      try {
        await this.getProviders({ params, fresh })
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message, 'danger')
        } else {
          throw error
        }
      }
    },

    statusClass (status) {
      if (status === 'enabled') {
        return 'status-success'
      } else if (status === 'pending') {
        return 'status-info'
      } else {
        return 'status-danger'
      }
    }
  }
}
</script>
