<template>
  <fragment>
    <app-header title="Organisation Subscription" v-if="$isA('provider')"/>
    <slot name="tabBar" />

    <div class="container container-max-md mt-4">
      <update-payment-method-card redirect-to="providers.single.subscription" />
    </div>
  </fragment>
</template>

<script>
import UpdatePaymentMethodCard from '@/components/subscriptions/UpdatePaymentMethodCard.vue'

export default {
  components: {
    UpdatePaymentMethodCard,
  },
}
</script>
