<template>
  <div class="card mt-4">
    <div class="card-body">
      <div class="row">
        <div class="col-md-4 col-lg col-xl-3 mb-3 mb-xl-0">
            <label class="form-label">Account Status</label>
            <single-select v-model="stateStatus" :options="optionsStatus" :preselect="false"/>
        </div>
        <div class="col-md-4 col-lg col-xl-3 mb-3 mb-xl-0">
          <label class="form-label">Course Check</label>
          <single-select v-model="stateRequiresCourseReview" :options="optionsCourseReview" :preselect="false"/>
        </div>
        <div class="col-md-4 col-lg col-xl-3 mb-3 mb-xl-0">
          <label class="form-label">Reports Access</label>
          <single-select v-model="stateReportsAccess" :options="optionsReportsAccess" :preselect="false"/>
        </div>
        <div class="col-lg col-xl-3 d-flex align-items-center text-end">
          <c-button class="btn-md mt-xl-4" @click="save" validate>
            Save Changes
          </c-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { call, get } from 'vuex-pathify'
import { stateMapper } from '@/vuex/modules/providers'
import { toast } from '@chameleon/chameleon-vue'
import SingleSelect from '@/components/forms/SingleSelect.vue'

export default {
  mixins: [stateMapper],

  components: {
    SingleSelect
  },

  data () {
    return {
      optionsStatus: [
        { label: 'Enabled', value: 'enabled' },
        { label: 'Pending', value: 'pending' },
        { label: 'Disabled', value: 'disabled' },
      ],
      optionsCourseReview: [
        { label: 'Enabled', value: true },
        { label: 'Disabled', value: false },
      ],
      optionsReportsAccess: [
        { label: 'Enabled', value: true },
        { label: 'Disabled', value: false },
      ]
    }
  },

  computed: {
    provider: get('providers/provider'),
  },

  methods: {
    updateProviderSettings: call('providers/updateProviderSettings'),

    async save () {
      await this.updateProviderSettings({
        id: this.provider.id,
        payload: {
          status: this.provider.status,
          requires_course_review: this.provider.requires_course_review,
          reports_access: this.provider.reports_access,
        }
      })

      toast('Provider settings saved.', 'success')
    }
  }
}
</script>
