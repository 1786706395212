<template>
  <div class="card">
    <div class="card-body">
      <div class="input-group">
        <div class="input-group-text"><i class="far fa-search"></i></div>
        <c-input type="text" placeholder="Search" class="form-control" @input="updateSearch" :value="filters.search" />
      </div>
    </div>
  </div>
</template>

<script>
import { useQueryParamFilters } from '@codium/codium-vue'
import { debounce } from 'lodash'

export default {
  mixins: [useQueryParamFilters()],

  data () {
    return {
      filters: {
        search: null,
      },
    }
  },

  computed: {
    updateSearch () {
      return debounce((value) => {
        this.filters.search = value
      }, 500)
    }
  }
}
</script>
