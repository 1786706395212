<template>
  <router-view>
    <template #tabBar>
      <c-horizontal-nav prevent-overflow>
        <c-horizontal-nav-link :to="{ name: 'providers.single' }">
          Details
        </c-horizontal-nav-link>
        <c-horizontal-nav-link :to="{ name: 'providers.single.subscription' }">
          Subscription
        </c-horizontal-nav-link>
        <c-horizontal-nav-link :to="{ name: 'providers.single.learner-quality-score' }">
          Learner Quality Score
        </c-horizontal-nav-link>
      </c-horizontal-nav>
    </template>
  </router-view>
</template>

<script>
import { helperMethods, helperComputed } from '@/vuex/modules/providers'
import { can } from '@codium/codium-vue'
import { toast } from '@chameleon/chameleon-vue'

export default {
  data () {
    return {
      pageTitle: 'Training Provider'
    }
  },

  computed: {
    ...helperComputed,
  },

  async created () {
    if (!this.$route.params.providerId) {
      return
    }

    try {
      await this.getProvider(this.$route.params.providerId)
      if (can('read users')) {
        return null
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast(error.response.data.message, 'danger')
      } else {
        throw error
      }
    }
  },

  methods: {
    ...helperMethods
  }
}
</script>
