<template>
  <fragment>
    <slot name="tabBar" />
    <div class="container mt-4">
      <users-list-filters v-model="params" />

      <c-data-list :items="users" @load="load">
        <template #columns>
          <div class="col-xl-4">
            <p>Name</p>
          </div>
          <div class="col-xl-3">
            <p>Email Address</p>
          </div>
          <div class="col-xl-3">
            <p>Account Type</p>
          </div>
          <div class="col-xl-2">
            <p>Status</p>
          </div>
        </template>

        <template #default="{ item }">
          <users-list-item :user="item" />
        </template>
      </c-data-list>
    </div>

    <slot name="footer" />

    <portal to="header">
      <router-link v-if="$isAn('admin')" class="btn btn-primary btn-md" :to="{ name: 'providers.single.users.add' }" >
        Add User
      </router-link>
    </portal>
  </fragment>
</template>

<script>
import { helperComputed, helperMethods } from '@/vuex/modules/providers/users'
import { compareQueryParams } from '@codium/codium-vue'
import UsersListItem from '@/views/users/UsersListItem'
import UsersListFilters from '@/views/users/UsersListFilters'
import { get } from 'vuex-pathify'

export default {
  components: {
    UsersListItem,
    UsersListFilters
  },

  data () {
    return {
      params: null
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getUsers({ params, fresh: true })
    })
  },

  beforeDestroy () {
    this.resetUsers()
  },

  computed: {
    ...helperComputed,
    ...get('providers', ['provider']),
  },

  methods: {
    ...helperMethods,

    async load (done) {
      const { params } = this

      try {
        await this.getUsers({ params })

        done()
      } catch {
        done(true)
      }
    }
  }
}
</script>
