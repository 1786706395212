<template>
  <fragment>
    <ul class="results-list">
      <template v-for="course in courses">
        <li :key="`course-${course.id}`">
            <article class="card card-course card-course-horizontal">
              <router-link
                :to="{ name: 'courses.show', params: { courseId: course.id } }"
                class="featured-container"
              >
                <img class="featured" :src="featuredImageUrl(course)" width="1280" height="720" />
                <span class="featured-topic" v-if="course.featured_topic_name">
                  {{ course.featured_topic_name }}
                </span>
              </router-link>
              <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col">
                        <h2>{{ course.name }}</h2>
                        <p class="text-muted">{{ course.provider_name }}</p>
                        <p>{{ course.short_description }}</p>
                    </div>
                    <div class="col-auto">
                      <router-link
                        :to="{ name: 'courses.show', params: { courseId: course.id } }"
                        class="btn btn-outline-primary"
                      >Score: {{ course.learner_quality_score !== null ? course.learner_quality_score : '-' }}</router-link>
                    </div>
                  </div>
              </div>
            </article>
        </li>
      </template>
    </ul>
  </fragment>
</template>

<script>
import { http } from '@/utils'
import { placeholders } from '@/utils/placeholders'

export default {
  components: {
  },

  props: {
    provider: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      courses: []
    }
  },

  async created () {
    const url = `providers/${this.provider.id}/average-courses-scores`
    const response = await http.get(url)
    this.courses = response.data.data
  },

  methods: {
    featuredImageUrl (course) {
      return course.featured_image_url ?? placeholders.large
    }
  }
}
</script>
