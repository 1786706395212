<template>
  <div class="card card-org-details">
    <img :src="featuredImageUrl" width="1280" height="720" class="featured" />
    <div class="card-body">
      <!-- name -->
      <validation-provider tag="div" class="mb-4" name="name" rules="required">
        <label class="form-label">Company / Organisation Name</label>
        <c-input type="text" v-model="stateName" />
        <c-help-block />
      </validation-provider>
      <!-- acronym -->
      <validation-provider tag="div" class="mb-4" name="provider acronym" rules="required">
        <label class="form-label">Company / Organisation Acronym</label>
        <c-input type="text" v-model="stateProviderAcronym" />
        <c-help-block />
      </validation-provider>
      <!-- address -->
      <div class="row">
        <div class="mb-4">
          <label class="form-label">Office Address</label>
          <google-map-autocomplete-input
            :value="provider.address"
            @input="updateAddress"
          />
        </div>
      </div>
      <div class="row">
        <!-- phone number -->
        <validation-provider
          tag="div"
          class="col-lg-6 mb-3"
          name="phone number"
          rules="required"
          v-slot="{ failed }"
        >
          <label class="form-label">Phone number</label>
          <imask-input
            v-model="statePhoneNumber"
            :class="{ 'is-invalid': failed }"
            class="form-control"
          />
          <c-help-block />
        </validation-provider>
        <!-- email -->
        <validation-provider
          tag="div"
          class="col-lg-6 mb-3"
          name="email"
          rules="required"
        >
          <label class="form-label">Email Address</label>
          <c-input type="text" v-model="stateEmail" />
          <c-help-block />
        </validation-provider>
      </div>
      <!-- website -->
      <validation-provider
        tag="div"
        class="mb-4"
        name="website"
        rules="required"
      >
        <label class="form-label">Website</label>
        <c-input type="text" v-model="stateWebsite" />
        <c-help-block />
      </validation-provider>
      <div class="row">
        <!-- square logo -->
        <validation-provider
          tag="div"
          class="col-lg-6 mb-3"
          name="square logo"
          vid="square_logo"
        >
          <label class="form-label">Square Logo</label>
          <file-upload
            v-model="squareLogo"
            v-bind="{ file: provider.square_logo }"
            @delete="deleteProviderSquareLogo"
          >
          <template #imageDimensionMessage>
              <span>
                Optimal image dimension: 512 x 512 pixels
              </span>
            </template>
          </file-upload>
          <c-help-block />
        </validation-provider>
        <!-- horizontal logo -->
        <validation-provider
          tag="div"
          class="col-lg-6 mb-3"
          name="horizontal logo"
          vid="horizontal_logo"
        >
          <label class="form-label">Horizontal Logo</label>
          <file-upload
            v-model="horizontalLogo"
            v-bind="{ file: provider.horizontal_logo }"
            @delete="deleteProviderHorizontalLogo"
          >
            <template #imageDimensionMessage>
              <span>
                Optimal image dimension: 3280 x 512 pixels
              </span>
            </template>
          </file-upload>
          <c-help-block />
        </validation-provider>
        <!-- featured image -->
        <validation-provider
          tag="div"
          class="col-lg-6 mb-3"
          name="featured image"
          vid="featured_image"
        >
          <label class="form-label">Featured Image</label>
          <file-upload
            v-model="featuredImage"
            v-bind="{ file: provider.featured_image }"
            @delete="deleteProviderFeaturedImage"
          >
            <template #imageDimensionMessage>
              <span>
                Optimal image dimension: 1920 x 1080 pixels
              </span>
            </template>
          </file-upload>
          <c-help-block />
        </validation-provider>
      </div>
      <!-- short description -->
      <validation-provider
        tag="div"
        class="mb-4"
        name="short description"
        vid="short_description"
        rules="required|max:280"
      >
        <label class="form-label">Short Description</label>
        <c-textarea type="text" v-model="stateShortDescription" />
        <c-help-block />
      </validation-provider>
      <!-- long description -->
      <validation-provider
        tag="div"
        class="mb-4"
        name="long description"
        rules="required"
      >
        <label class="form-label">Long Description</label>
        <quill-textarea v-model="stateLongDescription" />
        <c-help-block />
      </validation-provider>

      <div class="text-end mt-4">
        <slot name="button" v-bind="{ payload, saved }" />
      </div>
    </div>
  </div>
</template>

<script>
import { get, call } from 'vuex-pathify'
import { placeholders } from '@/utils/placeholders'
import { IMaskComponent } from 'vue-imask'
import { stateMapper } from '@/vuex/modules/providers'
import QuillTextarea from '@/components/forms/QuillTextarea'
import FileUpload from '@/components/forms/FileUpload.vue'
import GoogleMapAutocompleteInput from '@/components/maps/GoogleMapAutocompleteInput.vue'

export default {
  mixins: [stateMapper],

  components: {
    'imask-input': IMaskComponent,
    QuillTextarea,
    FileUpload,
    GoogleMapAutocompleteInput
  },

  data () {
    return {
      squareLogo: null,
      horizontalLogo: null,
      featuredImage: null,
      optionsStatus: [
        { label: 'Enabled', value: 'enabled' },
        { label: 'Disabled', value: 'disabled' },
        { label: 'Pending Verification', value: 'pending' }
      ]
    }
  },

  computed: {
    provider: get('providers/provider'),

    payload () {
      const payload = new FormData()

      payload.append('form', JSON.stringify({
        name: this.provider.name,
        provider_acronym: this.provider.provider_acronym,
        address: this.provider.address,
        phone_number: this.provider.phone_number,
        email: this.provider.email,
        website: this.provider.website,
        short_description: this.provider.short_description,
        long_description: this.provider.long_description,
      }))

      if (this.squareLogo) {
        payload.append('square_logo', this.squareLogo)
      }

      if (this.horizontalLogo) {
        payload.append('horizontal_logo', this.horizontalLogo)
      }

      if (this.featuredImage) {
        payload.append('featured_image', this.featuredImage)
      }

      return payload
    },

    featuredImageUrl () {
      return this.provider.featured_image_url ?? placeholders.large
    }
  },

  methods: {
    deleteProviderSquareLogo: call('providers/deleteProviderSquareLogo'),
    deleteProviderHorizontalLogo: call('providers/deleteProviderHorizontalLogo'),
    deleteProviderFeaturedImage: call('providers/deleteProviderFeaturedImage'),

    saved () {
      this.squareLogo = null
      this.horizontalLogo = null
      this.featuredImage = null
    },

    updateAddress ({ formattedAddress, latitude, longitude, components }) {
      this.stateAddressFormattedAddress = formattedAddress
      this.stateAddressLatitude = latitude
      this.stateAddressLongitude = longitude
      this.stateAddressStreet =
          components.long.street_number && components.long.route
            ? `${components.long.street_number} ${components.long.route}`
            : null
      this.stateAddressSuburb = components.long.locality
      this.stateAddressPostcode = components.long.postal_code ?? null
      this.stateAddressState = components.long.administrative_area_level_1
      this.stateAddressStateShort =
          components.short.administrative_area_level_1
      this.stateAddressCountry = components.long.country
    }
  }
}
</script>
