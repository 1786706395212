<template>
  <c-data-filters>
    <template #basic>
      <label class="sr-only">Search</label>

      <c-input type="text" :value="filters.search" @input="updateSearch">
        <i slot="prepend" class="fal fa-search" />
      </c-input>
    </template>

    <div class="row">
      <!-- search -->
      <div class="col-lg-8 col-xl-9 mb-3">
        <div class="mb-3">
          <label>Search</label>
          <c-input type="text" placeholder="Search" :value="filters.search" @input="updateSearch">
            <i slot="prepend" class="fal fa-search" />
          </c-input>
        </div>
      </div>
      <!-- status -->
      <div class="col-lg-4 col-xl-3 mb-3">
        <label>Status</label>
        <single-select v-model="filters.status" :options="statusOptions" />
      </div>
    </div>
  </c-data-filters>
</template>

<script>
import { useQueryParamFilters } from '@codium/codium-vue'
import { debounce } from 'lodash'
import SingleSelect from '@/components/forms/SingleSelect.vue'

export default {
  mixins: [useQueryParamFilters()],

  components: {
    SingleSelect,
  },

  data () {
    return {
      filters: {
        search: null,
        status: 'all',
      },
      statusOptions: [
        { label: 'All', value: 'all' },
        { label: 'Enabled', value: 'enabled' },
        { label: 'Pending', value: 'pending' },
        { label: 'Disabled', value: 'disabled' },
      ]
    }
  },

  computed: {
    updateSearch () {
      return debounce((value) => {
        this.filters.search = value
      }, 500)
    }
  }
}
</script>
