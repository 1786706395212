<template>
  <fragment>
    <app-header title="Organisation Subscription" v-if="$isA('provider')"/>
    <slot name="tabBar" />

    <div class="container container-max-md mt-4">
      <div class="card">
        <div class="card-body card-body-lg">
          <h2>{{ cardTitle }}</h2>

          <template v-if="step === 1">
            <p class="text-muted">
              Please select the plan you would like to resubscribe / change to.
              When downgrading your plan change will be scheduled for the start
              of your next billing period. Upgrades will happen immediately and
              new charges pro-rated on your next scheduled invoice.
            </p>

            <div class="decorative-divider"></div>

            <ul class="list-data list-data-plan-select no-hover no-shadow">
              <subscription-provider-tier-item
                v-for="pkg in subscriptionPlansOrdered"
                :key="pkg.id"
                :pricing="pkg"
                v-model="selected"
              />
            </ul>

            <p class="mt-3">
              <small class="text-muted font-italic">
                Prices shown are in Australian Dollars (AUD) and inclusive of GST.
              </small>
            </p>

            <div class="text-end">
              <button class="btn btn-primary" @click="step = 2">
                Next
              </button>
            </div>
          </template>

          <template v-else>
            <template>
              <div class="row my-3">
                <div class="col">
                  <p class="subscription-text"><strong>{{ selectedPlan.name }}</strong></p>
                </div>
                <div class="col-auto">
                  <span v-if="!enterprisePlanSelected" class="h4 text-primary">{{ selectedPlanPrice }} /{{ selectedPlan.billing_frequency }}</span>
                  <span v-else  class="h4 text-primary">Price on Application</span>
                </div>
              </div><!-- /.row -->

              <p class="subscription-text" style="white-space: pre-line;">{{ selectedPlan.description }}</p>

              <a @click.prevent="step = 1" class="text-info small">Change Plan</a>

              <template v-if="activeSubscription.payment_method && !enterprisePlanSelected">
                <h3 class="mt-4">Current Payment Method</h3>

                <div class="row mb-4 text-muted">
                  <div class="col-auto d-flex align-items-center">
                    <img
                      :src="paymentMethodLogo"
                      alt="payment logo"
                      width="64"
                    />
                  </div>
                  <div class="col-auto">&bull;&bull;&bull;&bull; {{ activeSubscription.payment_method.card_last_four }}</div>
                  <div class="col-auto">Expires: {{ paymentMethodExpires }}</div>
                  <div class="col text-end">
                    <a @click.prevent="changePaymentMethod = true" class="text-info">
                      <i class="far fa-pencil"></i>
                      Update
                    </a>
                  </div>
                </div><!-- /.row -->
              </template>

              <template v-if="!enterprisePlanSelected">
                <h2 class="mt-4">Plan Price</h2>

                <div class="row">
                  <div class="col-12 mb-3">
                    <div class="form-check">
                      <c-checkbox
                        v-model="useCustomPrice"
                        class="form-check-inline ps-0"
                        id="form-check-custom-price"
                      >
                        Custom Price
                      </c-checkbox>
                    </div>
                  </div>

                  <div class="col-sm-6 mb-3">
                      <label class="form-label">Price</label>
                      <div class="input-group">
                        <span class="input-group-text">$</span>

                        <c-number
                          v-model="customPrice"
                          class="form-control text-end"
                          placeholder="0.00"
                          :decimals="true"
                        />
                      </div>
                  </div>
                </div>
              </template>

              <div class="row mt-2">
                <div class="col-12 mb-3">
                  <div class="form-check">
                    <c-checkbox
                      v-model="sendSubscriptionEmails"
                      class="form-check-inline ps-0"
                      id="form-check-send-subscription-emails"
                    >
                      Send Subscription Emails
                    </c-checkbox>
                  </div>
                </div>
              </div>

              <div class="text-end mt-4">
                <c-button class="btn btn-md btn-primary" @click="changePlan">
                  {{ isChangingPlan ? 'Change Plan' : 'Subscribe' }}
                </c-button>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { call, get } from 'vuex-pathify'
import { http } from '@/utils'
import SubscriptionProviderTierItem from '@/components/subscriptions/SubscriptionProviderTierItem.vue'
import { toast } from '@chameleon/chameleon-vue'
import visaLogo from '@/assets/img/payment-logo/visa.svg'
import mastercardLogo from '@/assets/img/payment-logo/mastercard.svg'

export default {
  components: {
    SubscriptionProviderTierItem,
  },

  data () {
    return {
      step: 1,
      selected: null,
      changePaymentMethod: false,
      tokenize: null,
      customPrice: null,
      useCustomPrice: false,
      sendSubscriptionEmails: true,
    }
  },

  async created () {
    await this.getPackages({ role: 'provider' })
    await this.getActiveSubscription({
      type: 'providers',
      id: this.provider.id,
    }).catch(() => {})

    if (this.activeSubscription?.id) {
      this.selected = this.activeSubscription.package_pricing_id
    }
  },

  computed: {
    subscriptionPlan: get('packages/packages'),
    activeSubscription: get('activeSubscriptions/activeSubscription'),
    provider: get('providers/provider'),

    cardTitle () {
      if (this.step === 1) {
        return 'Step 1 of 2. Choose a plan.'
      }
      return 'Step 2 of 2. Confirm payment method.'
    },

    subscriptionPlansOrdered () {
      if (!this.subscriptionPlan?.pricings) {
        return []
      }

      // Sort packages by price, with enterprise package at the end
      return [...this.subscriptionPlan.pricings].sort((a, b) => {
        if (a.price === 0) return 1
        if (b.price === 0) return -1

        return a.price - b.price
      })
    },

    selectedPlan () {
      if (!this.subscriptionPlan?.pricings) {
        return null
      }

      return this.subscriptionPlan.pricings.find(p => p.id === this.selected)
    },

    selectedPlanPrice () {
      if (!this.selectedPlan) {
        return null
      }

      return (this.selectedPlan.price / 100).toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },

    paymentMethodLogo () {
      if (this.activeSubscription.payment_method?.card_brand === 'visa') {
        return visaLogo
      }

      if (this.activeSubscription.payment_method?.card_brand === 'mastercard') {
        return mastercardLogo
      }

      return null
    },

    paymentMethodExpires () {
      return `${this.activeSubscription.payment_method?.card_expiry_month}/${this.activeSubscription.payment_method?.card_expiry_year}`
    },

    isChangingPlan () {
      return this.selected !== this.activeSubscription.package_pricing_id
    },

    enterprisePlanSelected () {
      return this.selectedPlan?.name === 'Enterprise' && this.step === 2
    },
  },

  methods: {
    getPackages: call('packages/getPackages'),
    getActiveSubscription: call('activeSubscriptions/getActiveSubscription'),

    async changePlan () {
      try {
        await http.post(`providers/${this.provider.id}/subscribe`, {
          package_pricing_id: this.selected,
          custom_price: this.useCustomPrice ? this.customPrice : null,
          use_custom_price: this.useCustomPrice,
          send_subscription_emails: this.sendSubscriptionEmails,
        })

        await this.getActiveSubscription({
          type: 'providers',
          id: this.provider.id,
        })
        toast('Successfully changed plan.', 'success')
        this.$router.push({ name: 'admin.providers.single.subscription' })
      } catch (error) {
        toast(error.response.data.message, 'danger')
      }
    },
  },
}
</script>
