<template>
  <fragment>
    <app-header title="Organisation Subscription" v-if="$isA('provider')"/>
    <slot name="tabBar" />
    <div class="container container-max-md mt-4">
      <subscription-details-card />

      <component :is="invoiceListComponent" />
    </div>
  </fragment>
</template>

<script>
import { call, get } from 'vuex-pathify'
import SubscriptionDetailsCard from '@/components/subscriptions/SubscriptionDetailsCard.vue'
import InvoiceList from '@/components/subscriptions/InvoiceList.vue'
import InvoiceListAdmin from '@/components/providers/admin/InvoiceList.vue'

export default {
  components: {
    SubscriptionDetailsCard,
    InvoiceList,
    InvoiceListAdmin,
  },

  async created () {
    await this.getActiveSubscription({
      type: 'providers',
      id: this.$route.params.providerId
    }).catch(() => {})

    if (!this.activeSubscription?.id) {
      this.$router.push({ name: 'admin.providers.single.subscription.subscribe' })
    }
  },

  beforeDestroy () {
    this.resetActiveSubscription()
  },

  computed: {
    activeSubscription: get('activeSubscriptions/activeSubscription'),

    invoiceListComponent () {
      if (this.$isA('admin')) {
        return InvoiceListAdmin
      }

      return InvoiceList
    }
  },

  methods: {
    getActiveSubscription: call('activeSubscriptions/getActiveSubscription'),
    resetActiveSubscription: call('activeSubscriptions/resetActiveSubscription'),
  },
}
</script>
