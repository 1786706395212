<template>
  <fragment>
    <app-header title="Organisation Subscription" v-if="$isA('provider')"/>
    <slot name="tabBar" />

    <div class="container container-max-md mt-4">
      <invoice-single-details />
    </div>
  </fragment>
</template>

<script>
import InvoiceSingleDetails from '@/components/subscriptions/InvoiceSingleDetails.vue'

export default {
  components: {
    InvoiceSingleDetails
  }
}
</script>
