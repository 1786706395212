<template>
  <fragment>
    <app-header title="Organisation Subscription" v-if="$isA('provider')"/>
    <slot name="tabBar" />

    <div class="container container-max-md mt-4">
      <div class="card">
        <div class="card-body card-body-lg">
          <h2 v-if="!enterprisePlanSelected" >{{ cardTitle }}</h2>

          <template v-if="step === 1">
            <p class="text-muted">
              Please select the plan you would like to resubscribe / change to.
              When downgrading your plan change will be scheduled for the start
              of your next billing period. Upgrades will happen immediately and
              new charges pro-rated on your next scheduled invoice.
            </p>

            <div class="decorative-divider"></div>

            <ul class="list-data list-data-plan-select no-hover no-shadow">
              <subscription-provider-tier-item
                v-for="pkg in subscriptionPlansOrdered"
                :key="pkg.id"
                :pricing="pkg"
                v-model="selected"
              />
            </ul>

            <p class="mt-3">
              <small class="text-muted font-italic">
                Prices shown are in Australian Dollars (AUD) and inclusive of GST.
              </small>
            </p>

            <div class="text-end">
              <button class="btn btn-primary" :disabled="!isChangingPlan" @click="step = 2">
                Next
              </button>
            </div>
          </template>

          <template v-else>
            <template v-if="enterprisePlanSelected">
              <div class="row mb-5 justify-content-center">
                <div class="col-4">
                  <img
                    class="logo"
                    src="@/assets/img/logo/primary-stacked.svg"
                    alt="MedCPD"
                  />
                </div>
              </div>

              <h2>Enterprise Plan</h2>

              <p class="text-muted">{{ selectedPlan.description }}</p>

              <h2>Contact Us</h2>

              <p class="text-muted">
                To join our enterprise provider plan please get in contact with us
                using the details below so we can tailor a plan for your needs.
              </p>

              <div class="row mb-5">
                <div class="col-auto text-muted">Email:</div>

                <div class="col">
                  <a href="mailto:contact@medcpd.com" target="_blank">
                    contact@medcpd.com
                  </a>
                </div>
              </div>

              <div class="mt-5">
                <a @click="$router.go(-1)" class="text-muted">Back</a>
              </div>
            </template>

            <template v-else>
              <div class="row my-3">
                  <div class="col">
                    <p class="subscription-text"><strong>{{ selectedPlan.name }}</strong></p>
                  </div>
                  <div class="col-auto">
                    <span class="h4 text-primary">{{ selectedPlanPrice }} /month</span>
                  </div>
              </div><!-- /.row -->

              <p class="subscription-text" style="white-space: pre-line;">{{ selectedPlan.description }}</p>

              <a @click.prevent="step = 1" class="text-info small">Change Plan</a>

              <template v-if="!changePaymentMethod">
                <h3 class="mt-4">Current Payment Method</h3>

                <div class="row mb-4 text-muted">
                  <div class="col-auto d-flex align-items-center">
                    <img
                      :src="paymentMethodLogo"
                      alt="payment logo"
                      width="64"
                    />
                  </div>
                  <div class="col-auto">&bull;&bull;&bull;&bull; {{ activeSubscription.payment_method.card_last_four }}</div>
                  <div class="col-auto">Expires: {{ paymentMethodExpires }}</div>
                  <div class="col text-end">
                    <a @click.prevent="changePaymentMethod = true" class="text-info">
                      <i class="far fa-pencil"></i>
                      Update
                    </a>
                  </div>
                </div><!-- /.row -->
              </template>

              <template v-else>
                <h3 class="mt-4">Add Payment Method</h3>

                <p class="text-muted">
                  Enter the new card details to be used for future subscription
                  payments.
                </p>

                <add-card @tokenize="(t) => (tokenize = t)" @click="changePlan" hide-button />
              </template>

              <div class="text-end mt-4">
                <c-button class="btn btn-md btn-primary" @click="changePlan">
                  Change Plan
                </c-button>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { call, get } from 'vuex-pathify'
import { http } from '@/utils'
import SubscriptionProviderTierItem from '@/components/subscriptions/SubscriptionProviderTierItem.vue'
import AddCard from '@/components/payments/AddCard.vue'
import { toast } from '@chameleon/chameleon-vue'
import visaLogo from '@/assets/img/payment-logo/visa.svg'
import mastercardLogo from '@/assets/img/payment-logo/mastercard.svg'

export default {
  components: {
    SubscriptionProviderTierItem,
    AddCard,
  },

  data () {
    return {
      step: 1,
      selected: null,
      changePaymentMethod: false,
      tokenize: null,
    }
  },

  async created () {
    await this.getPackages()
    await this.getActiveSubscription({})

    this.selected = this.activeSubscription.package_pricing_id
  },

  computed: {
    subscriptionPlan: get('packages/packages'),
    activeSubscription: get('activeSubscriptions/activeSubscription'),

    cardTitle () {
      if (this.step === 1) {
        return 'Step 1 of 2. Choose a plan.'
      }
      return 'Step 2 of 2. Confirm payment method.'
    },

    subscriptionPlansOrdered () {
      if (!this.subscriptionPlan?.pricings) {
        return []
      }

      // Sort packages by price, with enterprise package at the end
      return [...this.subscriptionPlan.pricings].sort((a, b) => {
        if (a.price === 0) return 1
        if (b.price === 0) return -1

        return a.price - b.price
      })
    },

    selectedPlan () {
      if (!this.subscriptionPlan?.pricings) {
        return null
      }

      return this.subscriptionPlan.pricings.find(p => p.id === this.selected)
    },

    selectedPlanPrice () {
      if (!this.selectedPlan) {
        return null
      }

      return (this.selectedPlan.price / 100).toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },

    paymentMethodLogo () {
      if (this.activeSubscription.payment_method?.card_brand === 'visa') {
        return visaLogo
      }

      if (this.activeSubscription.payment_method?.card_brand === 'mastercard') {
        return mastercardLogo
      }

      return null
    },

    paymentMethodExpires () {
      return `${this.activeSubscription.payment_method?.card_expiry_month}/${this.activeSubscription.payment_method?.card_expiry_year}`
    },

    isChangingPlan () {
      return this.selected !== this.activeSubscription.package_pricing_id
    },

    enterprisePlanSelected () {
      return this.selectedPlan?.name === 'Enterprise' && this.step === 2
    },
  },

  methods: {
    getPackages: call('packages/getPackages'),
    getActiveSubscription: call('activeSubscriptions/getActiveSubscription'),

    async changePlan () {
      let paymentMethod = null

      if (this.tokenize) {
        paymentMethod = await this.tokenize()
      }

      await http.post('/subscription', {
        payment_method: paymentMethod?.id,
        package_pricing_id: this.selected,
      })

      await this.getActiveSubscription({})
      toast('Successfully changed plan.', 'success')
      this.$router.push({ name: 'providers.single.subscription' })
    },
  },
}
</script>
