<template>
  <fragment>
    <app-header title="Organisation Details" v-if="$isA('provider')"/>
    <slot name="tabBar" />
    <div class="container mt-4">
      <validation-observer ref="validation" @submit.native.prevent>
        <!-- provider form -->
        <input-form>
          <!-- submit -->
          <template #button="{ payload, saved }">
            <div class="text-end">
              <c-button class="px-4 btn-md" @click="submit(payload, saved)" validate>
                Save Changes
              </c-button>
            </div>
          </template>
        </input-form>
      </validation-observer>

      <settings-card v-if="$isA('admin')"/>
    </div>

    <slot name="footer" />
  </fragment>
</template>

<script>
import { get, call } from 'vuex-pathify'
import InputForm from '@/views/providers/ProviderForm'
import { toast } from '@chameleon/chameleon-vue'
import SettingsCard from '@/components/providers/admin/SettingsCard.vue'

export default {
  components: {
    InputForm,
    SettingsCard,
  },

  computed: {
    provider: get('providers/provider'),
  },

  methods: {
    updateProvider: call('providers/updateProvider'),

    async submit (payload, saved) {
      await this.updateProvider({ id: this.provider.id, payload })

      saved()

      toast('Provider saved', 'success')
    }
  }
}
</script>
