<template>
  <fragment>
    <app-header title="Add Training Provider" />
    <div class="container">
      <validation-observer ref="validation" @submit.native.prevent>
        <!-- provider form -->
        <input-form>
          <!-- submit -->
          <template #button="{ payload }">
            <div class="text-end">
              <c-button class="px-4" @click="submit(payload)" validate>
                Create
              </c-button>
            </div>
          </template>
        </input-form>
      </validation-observer>
    </div>
  </fragment>
</template>

<script>
import InputForm from '@/views/providers/ProviderForm'
import { toast } from '@chameleon/chameleon-vue'
import { call } from 'vuex-pathify'

export default {
  components: {
    InputForm
  },

  beforeDestroy () {
    this.resetProvider()
  },

  methods: {
    storeProvider: call('providers/storeProvider'),
    resetProvider: call('providers/resetProvider'),

    async submit (payload) {
      await this.storeProvider(payload)
      toast('Provider added', 'success')
      this.$router.push({ name: 'providers' })
    }
  }
}
</script>
