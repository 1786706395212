<template>
  <fragment>
    <app-header title="Training Providers">
    </app-header>

    <div class="container">
      <list-filters v-model="params" />

      <c-data-list :items="items" @load="load" class="no-hover list-data-providers">
        <template #default="{ item }">
          <div class="row">
            <div class="col-lg-auto">
              <router-link :to="{ name: 'providers.show', params: { providerId: item.id } }">
                <img v-if="item.square_logo_url" :src="item.square_logo_url" width="256" height="256" alt="" class="logo">
                <img v-else :src="placeholders.square" width="256" height="256" alt="" class="logo">
              </router-link>
            </div>
            <div class="col-lg d-flex align-items-center">
              <div>
                <h2>
                  <router-link :to="{ name: 'providers.show', params: { providerId: item.id } }">{{ item.name }}</router-link>
                </h2>
                <div class="score-n-responses row my-2 col-12 col-3xl-8">
                  <provider-average :provider="item" :stacked="true"></provider-average>
                </div>
                <p>{{ item.short_description }}</p>
                <router-link :to="{ name: 'providers.show', params: { providerId: item.id } }" class="btn btn-md btn-primary me-2">Provider Details</router-link>
                <router-link :to="{ name: 'courses', params: { providerId: item.id } }" class="btn btn-md btn-outline-primary">View Courses</router-link>
              </div>
            </div>
          </div>
        </template>
      </c-data-list>
    </div>

    <slot name="footer" />
  </fragment>
</template>

<script>
import { compareQueryParams } from '@codium/codium-vue'
import ListFilters from './ProvidersListFilters'
import { placeholders } from '@/utils/placeholders'
import { toast } from '@chameleon/chameleon-vue'
import { get, call } from 'vuex-pathify'
import ProviderAverage from './ProviderAverage.vue'

export default {
  components: {
    ListFilters,
    ProviderAverage
  },

  data () {
    return {
      params: null,
      items: [],
      placeholders
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getList({ params, fresh: true })
    })
  },

  computed: {
    ...get('providers', ['providers']),
  },

  beforeDestroy () {
    this.resetProviders()
  },

  methods: {
    ...call('providers', ['getProviders', 'resetProviders']),
    async load (done) {
      const { params } = this
      try {
        await this.getList({ params })
        done()
      } catch {
        done(true)
      }
    },

    async getList ({ params = {}, fresh = false }) {
      try {
        await this.getProviders({ params, fresh })
        this.items = this.providers
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message, 'danger')
        } else {
          throw error
        }
      }
    },
  }
}
</script>
