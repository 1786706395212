<template>
  <validation-observer tag="form" class="container container-max-md" @submit.prevent ref="validation" >
    <form class="card" @submit.prevent>
      <div class="card-body card-body-lg">
        <p>
          When adding a user to your organisation they will be sent an email to create an account.
        </p>

        <div class="mb-3">
          <p class="form-label">First Name</p>
          <div class="row">
            <validation-provider tag="div" class="col-5 col-sm-4" name="title" rules="required" >
              <label class="visually-hidden">Title</label>
              <title-select v-model="stateTitle" />
              <c-help-block />
            </validation-provider>

            <validation-provider tag="div" class="col" name="first name" rules="required" >
              <label class="visually-hidden">First Name</label>
              <c-input type="text" v-model="stateFirstName" />
              <c-help-block />
            </validation-provider>
          </div>
        </div>

        <validation-provider tag="div" class="mb-3" name="last name" rules="" >
          <label class="form-label">Last Name</label>
          <c-input type="text" v-model="stateLastName" />
          <c-help-block />
        </validation-provider>

        <validation-provider tag="div" class="mb-4" name="email" rules="required|email" >
          <label class="form-label">Email</label>
          <c-input type="email" v-model="stateEmail" />
          <c-help-block />
        </validation-provider>

        <validation-provider tag="div" class="mb-4" name="mobile" rules="" v-slot="{ invalid }">
          <label class="form-label">Mobile</label>
          <phone-input v-model="stateMobile" :invalid="invalid"/>
          <c-help-block />
        </validation-provider>

        <div class="text-end">
          <c-button variant="primary" @click="submit" validate>
            Add User
          </c-button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { helperMethods, helperComputed, stateMapper } from '@/vuex/modules/providers/users'
import { toast } from '@chameleon/chameleon-vue'
import TitleSelect from '@/components/users/TitleSelect.vue'
import PhoneInput from '@/components/forms/PhoneInput.vue'

export default {
  mixins: [stateMapper],

  computed: helperComputed,

  components: {
    TitleSelect,
    PhoneInput,
  },

  created () {
    this.resetUser()
  },

  beforeDestroy () {
    this.resetUser()
  },

  methods: {
    ...helperMethods,

    async submit () {
      try {
        await this.storeUser(
          {
            title: this.user.title,
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            email: this.user.email,
            mobile: this.user.mobile,
          }
        )

        toast('Saved successfully', 'success')

        this.$router.push({ name: 'providers.single.users' })
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message, 'danger')
        } else {
          throw error
        }
      }
    }
  }
}
</script>
