<template>
  <div class="card" v-show="$isNotA('provider') || !hideInvoices">
    <div class="card-body pb-0">
      <div class="row">
          <div class="col d-flex align-items-center">
              <h2 class="m-0">Invoices</h2>

          </div>
          <div class="col-auto">
            <div class="input-group">
              <span class="input-group-text"><i class="fal fa-calendar-alt"></i></span>

              <label class="visually-hidden">From Date</label>
              <flatpickr
                id="firstRangeInput"
                :value="dateRangeFrom"
                class="form-control date-range form-control-sm"
                :config="dateRangeConfig"
                @on-change="dateRangeChanged"
                @on-close="dateRangeClosed"
              />

              <span class="input-group-text">-</span>

              <label class="visually-hidden">To Date</label>
              <flatpickr
                id="secondRangeInput"
                :value="dateRangeTo"
                class="form-control date-range form-control-sm"
                :config="dateRangeConfig"
                @on-change="dateRangeChanged"
                @on-close="dateRangeClosed"
              />
            </div>
          </div>
      </div>
    </div>

    <c-data-list
      :items="invoices"
      @load="load"
      class="list-data"
      remove-default-class
    >
      <template #columns>
        <div class="col">Date Due</div>
        <div class="col">Invoice Number</div>
        <div class="col text-end">Amount</div>
      </template>

      <template #default="{ item: invoice }">
        <invoice-list-item :invoice="invoice" />
      </template>
    </c-data-list>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { get, call } from 'vuex-pathify'
import InvoiceListItem from './InvoiceListItem.vue'
import Flatpickr from 'vue-flatpickr-component'
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin'

export default {
  components: {
    InvoiceListItem,
    Flatpickr,
  },

  data () {
    return {
      hideInvoices: false,
      params: {
        due_date_from: null,
        due_date_to: null,
      },
      dateRangeConfig: {
        altInput: true,
        altFormat: 'd/m/Y',
        mode: 'range',
        showMonths: 2,
        plugins: [
          // eslint-disable-next-line new-cap
          new rangePlugin({ input: '#secondRangeInput' })
        ],
      },
    }
  },

  async created () {
    try {
      await this.getProviderInvoices({
        params: this.params,
        providerId: this.$route.params.providerId
      })

      if (this.invoices.length < 1) {
        this.hideInvoices = true
      }
    } catch (err) {}
  },

  computed: {
    invoices: get('invoices/invoices'),

    dateRangeFrom: {
      get () {
        return this.params.due_date_from
      },
      set (value) {
        //
      }
    },

    dateRangeTo: {
      get () {
        return this.params.due_date_to
      },
      set (value) {
        //
      }
    },
  },

  watch: {
    params: {
      handler: async function (params) {
        this.resetInvoices()
        await this.getProviderInvoices({
          params: this.params,
          providerId: this.$route.params.providerId
        })
      },
      deep: true,
    }
  },

  beforeDestroy () {
    this.resetInvoices()
  },

  methods: {
    getProviderInvoices: call('invoices/getProviderInvoices'),
    resetInvoices: call('invoices/resetInvoices'),

    async load (done) {
      const { params } = this
      try {
        await this.getProviderInvoices({
          params: params,
          providerId: this.$route.params.providerId
        })

        done()
      } catch (err) {
        done(true)
      }
    },

    dateRangeClosed (dates) {
      if (dates.length !== 2) {
        this.params.due_date_from = null
        this.params.due_date_to = null
      }
    },

    dateRangeChanged (dates) {
      let startDate
      let endDate

      if (dates.length === 2) {
        [startDate, endDate] = dates.map(date => dayjs(date))

        // If getting dates in UTC we'll make sure dates are at the start/end of the day before converting to
        // UTC then getting the ISO 8601 string.
        if (this.utc) {
          startDate = startDate.startOf('day').utc().toISOString()
          endDate = endDate.endOf('day').utc().toISOString()
        } else {
          startDate = startDate.format('YYYY-MM-DD')
          endDate = endDate.format('YYYY-MM-DD')
        }
      } else if (dates.length === 0) {
        startDate = null
        endDate = null
      } else {
        return
      }

      if (startDate === this.params.due_date_from && endDate === this.params.due_date_to) {
        return
      }

      this.params.due_date_from = startDate
      this.params.due_date_to = endDate
    },
  },
}
</script>
